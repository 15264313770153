// copied from checkout-utils
type Success<TResult> = [undefined, TResult];
type Failure<TError> = [TError, undefined];
type ResultType<TResult, TError> = Success<TResult> | Failure<TError>;

type WrappedFunctionOrValue<TResult> = (() => Promise<TResult>) | Promise<TResult>;

async function tryCatch<TResult, TError = Error>(
  asyncFunctionOrPromise: WrappedFunctionOrValue<TResult>
): Promise<ResultType<TResult, TError>> {
  try {
    const result =
      typeof asyncFunctionOrPromise === 'function'
        ? await asyncFunctionOrPromise()
        : await asyncFunctionOrPromise;
    return success(result);
  } catch (error) {
    if (error instanceof Error) {
      return failure(error as TError);
    }
    return failure(new Error(`unkown error caught: ${JSON.stringify(error)}`) as TError);
  }
}

function tryCatchSync<TResult, TError = Error>(fn: () => TResult) {
  try {
    return success(fn());
  } catch (error) {
    if (error instanceof Error) {
      return failure(error as TError);
    }
    return failure(new Error(`unkown error caught: ${JSON.stringify(error)}`) as TError);
  }
}

function success<T>(result: T): Success<T> {
  return [undefined, result];
}
function failure<T>(error: T): Failure<T> {
  return [error, undefined];
}

const Result = {
  success,
  failure,
};

export { tryCatch, tryCatchSync, Result, ResultType };
