import { DeployEnv } from '@/utils/deployEnv';
import { configureClientLogger } from '@/utils/logger/client.logger';
import { ClientLogger } from '@/utils/logger/types';
import { createContext, useMemo } from 'react';

type ClientLoggerContextType = {
  logger: ClientLogger;
};

const initialMockLogger = {
  logger: {
    error: () => Promise.resolve(undefined),
    warn: () => Promise.resolve(undefined),
    info: () => Promise.resolve(undefined),
    debug: () => Promise.resolve(undefined),
  },
};
const ClientLoggerContext = createContext<ClientLoggerContextType>(
  initialMockLogger as unknown as ClientLoggerContextType
);

const ClientLoggerProvider = ({
  children,
  baseUrl,
  environment,
}: {
  children: React.ReactNode;
  baseUrl: string;
  environment: DeployEnv;
}) => {
  const { getLogger } = useMemo(
    () =>
      configureClientLogger({
        baseUrl,
        environment,
      }),
    [baseUrl, environment]
  );

  const loggerContext = useMemo(() => {
    const logger = getLogger();
    const trace = {
      path: typeof window === 'undefined' ? '' : window.location.pathname,
      userAgent: typeof window === 'undefined' ? '' : window.navigator.userAgent,
    };
    logger.setTrace(trace);

    return { logger };
  }, [getLogger]);

  return (
    <ClientLoggerContext.Provider value={loggerContext}>{children}</ClientLoggerContext.Provider>
  );
};

export { ClientLoggerProvider, ClientLoggerContext };
