import { hydrateRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Globals, StyleReset } from '@bwoty-web/ui-kit';
import App from './App';
import { AppInitialState } from './server/utils/getInitialStateForServerRender';

function hydrate() {
  const container = document.querySelector('[login-section]');
  const el = document.querySelector('[nonce]');

  // @ts-expect-error this exists it's fine
  const getServerSidePropsData = window.__INITIAL_STATE_LOGIN__ ?? {};
  const initialState = getServerSidePropsData.initialState as AppInitialState;
  if (!el?.parentElement) {
    throw new Error('No nonce element found in the document, cannot hydrate');
  }

  if (!container) {
    throw new Error('No container found in the document, cannot hydrate');
  }
  el.parentElement.removeChild(el);

  const app = (
    <StrictMode>
      <BrowserRouter>
        <Globals
          siteId={parseInt(getServerSidePropsData.siteId, 10)}
          legacyMode={getServerSidePropsData.legacyMode === 'true'}
        >
          <StyleReset />
          <App initialState={initialState} serverSideData={getServerSidePropsData} />
        </Globals>
      </BrowserRouter>
    </StrictMode>
  );
  hydrateRoot(container, app);
}

hydrate();
