import React from 'react';

/**
 * Small utility component for using umbraco texts with placeholders
 *
 * @example
 * <FormattedMessage text="Hello {name}, today is {day}" values={{name: "Alex", day: "Monday"}} />
 *
 *
 * @param {{values:{[key: string]: string}, text: string}} props
 * @returns {JSX.Element}
 */
// eslint-disable-next-line import/prefer-default-export
export const FormattedMessage = ({ values = {}, text = '' }) => {
  const tokenRegex = /{(\w*)}/;
  const words = text.split(tokenRegex);

  return words.map((part, index) => {
    const key = Object.keys(values).find((k) => part.includes(k));
    const value = values[key];
    if (!value) {
      return part;
    }
    if (React.isValidElement(value)) {
      // eslint-disable-next-line react/no-array-index-key
      return React.cloneElement(value, { key: index });
    }
    return value;
  });
};
