import { Link, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { SITE_IDS } from '@/utils/siteId';
import { Button } from '@bwoty-web/styleguide';
import { TextInput } from '@bwoty-web/ui-kit';
import styles from './Standalone.module.css';
import routes from '.';
import ving from '../assets/images/ving_logo_wave.webp';
import spies from '../assets/images/spies_logo_wave.webp';
import tjareborg from '../assets/images/tjareborg_logo_wave.webp';
import globetrotter from '../assets/images/globetrotter_logo_new.webp';

const siteIds = {
  [SITE_IDS.VINGSE]: 'Ving SE (1)',
  [SITE_IDS.VINGNO]: 'Ving NO (3)',
  [SITE_IDS.SPIES]: 'Spies DK (11)',
  [SITE_IDS.TJAREBORG]: 'Tjäreborg FI (15)',
  [SITE_IDS.GLOBETROTTER]: 'Globetrotter (18)',
  [SITE_IDS.OBC_GRAN_CANARIA]: 'OBC - Gran Canaria (135)',
};

const logos = {
  [SITE_IDS.VINGSE]: <img src={ving} alt="Ving logo" className={styles.logo} />,
  [SITE_IDS.VINGNO]: <img src={ving} alt="Ving logo" className={styles.logo} />,
  [SITE_IDS.SPIES]: <img src={spies} alt="Spies logo" className={styles.logo} />,
  [SITE_IDS.TJAREBORG]: <img src={tjareborg} alt="Tjäreborg logo" className={styles.logo} />,
  // prettier-ignore
  [SITE_IDS.GLOBETROTTER]: <img src={globetrotter} alt="Globetrotter logo" className={styles.logo} />,
  [SITE_IDS.OBC_GRAN_CANARIA]: <img src={ving} alt="Ving logo" className={styles.logo} />,
};

export const getServerSideProps = (_, req) => ({
  user: req.context?.user || {},
});

export default function Standalone(props) {
  const [searchParams] = useSearchParams();
  const { user } = props;
  const [token, setToken] = useState('');

  const StandaloneRoutes = routes().reduce((acc, route) => {
    if (route.name === '/loginmyhotel') {
      acc.push({
        ...route,
        friendlyName: `${route.friendlyName} [added query: siteId=65&siteIdHierarchy=136,65&redirect=<url>]`,
        path: `${route.path}`,
        querystring: `siteId=65&siteIdHierarchy=136,65&redirect=${encodeURIComponent(
          '/holiday-planner?categoryIds=1141&dayIndex=0'
        )}`,
      });
    }

    acc.push(route);

    return acc;
  }, []);

  const [loggedInUser, setLoggedInUser] = useState(user);
  const [loggedIn, setLoggedIn] = useState(Object.keys(user).length > 0);

  const [siteId, setSiteId] = useState(
    searchParams.get('siteId') ?? parseInt(Object.keys(siteIds)[0])
  );

  const handleOnLogoutClick = async () => {
    await fetch('/api/logout');
    setLoggedInUser({});
    setLoggedIn(false);
  };

  const diagnosticRoutes = [
    {
      label: 'Probe',
      to: '/probe',
    },
    {
      label: 'Smoke Test',
      to: '/smoke',
    },
  ];

  /**
   *     membershipId: sub,
    email,
    givenName: given_name,
   */
  return (
    <section className={styles.container}>
      {logos[siteId]}

      <div className={styles.logoutContainer}>
        <h1>Login</h1>
        {loggedIn && (
          <ul className={styles.loggedInDetails}>
            <li>Logged in! 🍆🍑🤠</li>
            <li>email: {loggedInUser.email}</li>
            <li>givenName: {loggedInUser.givenName}</li>
            <li>membershipId: {loggedInUser.membershipId}</li>
          </ul>
        )}

        <Button theme="primary" disabled={!loggedIn} onClick={handleOnLogoutClick}>
          {loggedIn ? 'Log out' : 'Logged out'}
        </Button>
      </div>

      <div className={styles.queryInput}>
        <p style={{ marginBottom: 8 }}>Standalone entry query string params</p>
        <label>
          <h3 style={{ marginBottom: 8 }}>siteId</h3>
          <select
            value={siteId}
            onChange={(event) => {
              setSiteId(Number.parseInt(event.target.value));
            }}
          >
            {Object.keys(siteIds).map((id) => (
              <option key={id} value={id}>
                {siteIds[id]}
              </option>
            ))}
          </select>
        </label>
        <label>
          <h3 style={{ marginBottom: 8 }}>Token</h3>
          <TextInput onChange={(e) => setToken(e.target.value)} value={token} />
        </label>
      </div>
      <ul className={styles.unorderedList}>
        {StandaloneRoutes.map(({ path, friendlyName }) => {
          const queryParams = new URLSearchParams();

          if (!path.includes('siteId')) {
            queryParams.append('siteId', siteId);
          }

          const appendToken = path.includes('choosepassword') || path.includes('newpassword');

          if (appendToken && token !== undefined) {
            console.log('appending token', path);
            queryParams.append('token', token);
          }

          const url = `${path}?${queryParams.toString()}`;

          return (
            <li key={friendlyName}>
              <span>
                <h2>
                  <Link reloadDocument to={url}>
                    {`${friendlyName} : ${path.toLowerCase()}`}
                  </Link>
                </h2>
                <Link
                  id={`${friendlyName.replaceAll(' ', '-').toLowerCase()}-markup`}
                  reloadDocument
                  to={`/markup${url}`}
                >
                  [markup]
                </Link>
              </span>
            </li>
          );
        })}
      </ul>
      <h2>Diagnostics</h2>
      <ul>
        {diagnosticRoutes.map((route) => (
          <li key={route.label}>
            <Link reloadDocument to={route.to}>
              {route.label}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
}
