import { createContext, ReactNode, useMemo } from 'react';

type PageContextType = {
  baseUrl: string;
  texts: Record<string, any>;
  siteId: number;
};

export const PageContext = createContext<PageContextType>(undefined as unknown as PageContextType);

interface PageContext {
  children: ReactNode;
  baseUrl: string;
  texts: Record<string, any>;
  siteId: string;
}

export function PageContextProvider({ children, baseUrl, texts, siteId }: PageContext) {
  const value = useMemo(
    () => ({ baseUrl, texts, siteId: parseInt(siteId) }),
    [baseUrl, texts, siteId]
  );
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}
