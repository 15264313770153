import { PropsWithChildren, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import classNames from 'classnames';
import styles from './App.module.css';
import routes from './sections';
import { SITE_IDS } from './utils/siteId';
import { PageContextProvider } from './context/pageContext';
import { ClientLoggerProvider } from './context/loggerContext';
import { AppInitialState } from './server/utils/getInitialStateForServerRender';
import { useLogger } from './hooks/useLogger';

export type AppProps = {
  serverSideData: {
    user: unknown;
    texts: Record<string, any>;
    siteId: string;
    legacyMode: string;
    name: string;
  };
  initialState: AppInitialState;
};

export default function App(props: AppProps) {
  return (
    <PageContextProvider
      baseUrl={props.initialState.baseUrl}
      texts={props.serverSideData.texts}
      siteId={props.serverSideData.siteId}
    >
      <ClientLoggerProvider
        baseUrl={props.initialState.baseUrl}
        environment={props.initialState.environment}
      >
        <LoginRoutes {...props} />
      </ClientLoggerProvider>
    </PageContextProvider>
  );
}

const clientRoutes = routes();

function checkTheme(legacyMode: string, siteId: string) {
  if (parseInt(siteId, 10) === SITE_IDS.GLOBETROTTER && legacyMode !== 'true') {
    return 'globe-theme';
  }
  return 'default-theme';
}

function LoginRoutes(props: AppProps) {
  const { serverSideData } = props;
  const { pathname } = useLocation();
  const logger = useLogger();

  const newRoutes = [
    ...clientRoutes,
    ...serverSideData.texts['routes'].map((route: { itemKey: string; href: { url: string } }) => {
      const routeCopy = clientRoutes.find(
        (clientRoute) => clientRoute.name === `/${route.itemKey}`
      );
      return { ...routeCopy, path: route.href.url };
    }),
  ];

  const theme = checkTheme(serverSideData.legacyMode, serverSideData.siteId);
  return (
    <ErrorBoundary
      fallback={<h1>Oops...</h1>} /** TODO */
      onError={(error) => {
        logger.error('[ErrorBoundary] caught an error', { error });
      }}
    >
      <Routes location={serverSideData.name || pathname}>
        {newRoutes.map(({ name, path, component }) => {
          let newPath = path;
          if (name === '/standalone') {
            newPath = '/';
          }
          const Section = component;
          return (
            <Route
              key={name}
              path={newPath}
              element={
                <div className={classNames(styles.app, theme)}>
                  <SectionWithSuspense component={Section} {...serverSideData} />
                </div>
              }
            />
          );
        })}
        <Route key="somethingwentwrong" path="*" element={<h1>Något gick fel!</h1>} />
      </Routes>
    </ErrorBoundary>
  );
}

function SectionWithSuspense({
  component: Section,
  ...props
}: PropsWithChildren<{ component: (props: any) => JSX.Element }>) {
  return (
    <Section {...props}>
      <Suspense fallback={<div>Loading...</div>}>{props.children}</Suspense>
    </Section>
  );
}
