import { TextInput } from '@bwoty-web/ui-kit';
import { useEffect, useRef } from 'react';
import styles from './ValidatedTextInput.module.css';

function ValidatedTextInput({
  handleInputChange,
  value,
  validationMessages,
  setValidationMessages,
  texts,
  validationKey,
  focusInvalidElement,
  ...rest
}) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (validationMessages[0] === validationKey && inputRef.current && focusInvalidElement) {
      inputRef.current.focus();
    }
  }, [validationMessages, validationKey, focusInvalidElement]);

  const handleOnChange = (e, validation) => {
    const newValidationMessages = validationMessages.filter(
      (validationMessage) => validationMessage !== validation
    );
    setValidationMessages(newValidationMessages);

    handleInputChange(e);
  };
  return (
    <TextInput
      value={value}
      onChange={(e) => handleOnChange(e, validationKey)}
      className={`${styles.textInput} ${validationKey}`}
      hasError={validationMessages.includes(validationKey)}
      validationText={texts.validation[validationKey]}
      aria-invalid={validationMessages.includes(validationKey)}
      aria-describedby={validationKey}
      ref={inputRef} // Attach internal ref to TextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

export default ValidatedTextInput;
