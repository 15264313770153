import { lazy } from 'react';

export const serverSideProps = (sectionName) => {
  const modules = import.meta.globEager('./**.jsx');
  const name = Object.keys(modules).find((moduleName) => moduleName.includes(sectionName));
  const { getServerSideProps } = modules[name];

  return (
    getServerSideProps ||
    (async () => {
      /* If no getServerSideProps fn is exported from the section, we can just return an empty function */
    })
  );
};

const routes = () => {
  const modules = import.meta.glob('./**.jsx');
  const sections = Object.keys(modules);

  return sections.map((sectionPath) => {
    const fileName = sectionPath.match(/^\.\/(.+)?(\.jsx)$/)[1];
    const name = `/${fileName.toLowerCase()}`;
    const friendlyName = fileName.match(/[A-Z][a-z]+/g)?.join(' ');

    /**
     * If sections have several exports (getServerSideProps for example)
     * Vite and react-refresh will detect this and deem HMR potentially
     * risk-full and instead trigger a page reload.
     */
    if (import.meta.hot) {
      import.meta.hot.accept();
    }

    /**
     * If we do not require some sections to lazy load, we can handle these separate
     * by finding it and returning the component directly
     */
    const component = lazy(modules[sectionPath]);

    return {
      name,
      friendlyName,
      path: name,
      file: `${fileName}.jsx`,
      component,
    };
  });
};

export default routes;
