import { ClientLoggerContext } from '@/context/loggerContext';
import { useContext } from 'react';

export function useLogger() {
  const loggerContext = useContext(ClientLoggerContext);

  if (!loggerContext) {
    throw new Error('👮 [useLogger]: needs to be wrapped in a <ClientLoggerContext.Provider>');
  }

  return loggerContext.logger;
}
