import unfetch from 'isomorphic-unfetch';

// eslint-disable-next-line consistent-return
const fetch = async (url, options = {}) => {
  const isNode = typeof window === 'undefined';

  const requestOptions = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
    credentials: 'include',
    body: JSON.stringify(options.body),
  };

  const timeout = options.timeout || 10000;
  const controller = new AbortController();
  requestOptions.signal = controller.signal;

  const timeoutId = setTimeout(() => {
    controller.abort();
  }, timeout);

  if (isNode) {
    const { httpAgent, httpsAgent } = await import('./httpAgent');
    requestOptions.agent = url.startsWith('https://') ? httpsAgent : httpAgent;
  }

  const res = await unfetch(url, requestOptions);
  clearTimeout(timeoutId);

  if (res.json) {
    const json = await res.json();
    return {
      ...json,
      error: !res.ok ? res.status.toString() : undefined,
      status: res.status,
      statusText: res.statusText,
    };
  }

  if (res.text) {
    return {
      text: [await res.text()],
      error: !res.ok ? res.status.toString() : undefined,
      status: res.status,
      statusMessage: res.statusMessage,
      ok: res.ok,
    };
  }
};

export default fetch;
