/* eslint-disable react/function-component-definition */
import { useState, useEffect } from 'react';
import LZString from 'lz-string';

import { ValidatedTextInput } from '@/components';
import classNames from 'classnames';
import {
  Icon,
  USER_INTERFACE_PASSWORD_EYE_HIDE,
  USER_INTERFACE_PASSWORD_EYE_SHOW,
} from '@bwoty-web/styleguide';
import { Button, Message, Link, Typography, Checkbox } from '@bwoty-web/ui-kit';
import { FormattedMessage } from '@/components/FormattedMessage';
import logToDataLayer from '@/utils/dataLayer';
import { useLogger } from '@/hooks/useLogger';
import { useSearchParams } from 'react-router-dom';
import { useIsGlobetrotter } from '@bwoty-web/ui-kit/hooks';
import styles from './styles.module.css';
import fetch from '../utils/fetch';

export function getServerSideProps(props) {
  return { siteId: props.siteId, baseUrl: props.baseUrl, legacyMode: props.legacyMode };
}

export default function Login(props) {
  const { texts, siteId, baseUrl } = props;

  const privacyLink = texts.links.find((link) => link.itemKey === 'privacy');
  const createAccountLink = texts.links.find((link) => link.itemKey === 'newaccount');

  return (
    <div className={styles.loginPage}>
      <LoginForm
        texts={texts}
        siteId={siteId}
        baseUrl={baseUrl}
        showForgotPasswordLink
        heading={
          <Typography as="h1" variant="headline-m" className={styles.title}>
            {texts.login}
          </Typography>
        }
      />
      <div className={styles.loginCreateAccountContainer}>
        <div className={styles.dividerContainer}>
          <hr className={classNames('solid', styles.divider)} />
          <p className={styles.dividerText}>{texts.or}</p>
          <hr className={classNames('solid', styles.divider)} />
        </div>
        <Button variant="secondary" className={styles.button} href={createAccountLink.href.url}>
          {texts.createAccount}
        </Button>
        <div className={styles.personalDataLink}>
          <Link href={privacyLink.href.url} className={styles.link}>
            {`${texts.personalDataLink} ${privacyLink.title}`}
          </Link>
        </div>
      </div>
    </div>
  );
}

export const LoginForm = (props) => {
  const {
    texts,
    siteId,
    baseUrl,
    siteIdSelector,
    heading,
    showForgotPasswordLink,
    customRedirect,
    disabled = false,
    myHotelsMainSiteId, // for fetching correct attribute collections for myhotels, not used in regular login
    mainSiteUrl, // base url to append to links if we are at MyHotels Login for correct linking
  } = props;

  const logger = useLogger();

  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [focusInvalidElement, setFocusInvalidElement] = useState(false);

  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [validationMessages, setValidationMessages] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isGlobetrotter = useIsGlobetrotter();

  const forgottenPasswordLink = texts.routes.find((route) => route.itemKey === 'forgottenpassword');

  const forgottenPasswordUrl = mainSiteUrl
    ? `${mainSiteUrl}${forgottenPasswordLink.href.url}`
    : forgottenPasswordLink.href.url;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    const inviteToken = searchParams.get('Invite') || searchParams.get('invite');
    const encodedPassword = LZString.compressToEncodedURIComponent(password);

    const body = {
      email,
      password: encodedPassword,
      siteId,
      rememberMe,
      inviteToken,
      customRedirect,
      myHotelsMainSiteId,
      mainSiteId: siteId,
    };
    setIsLoading(true);
    setErrorMessage('');

    try {
      setValidationMessages([]);
      const { messages, error, redirectUrl } = await fetch(`${baseUrl}/api/login`, {
        method: 'POST',
        body,
      });

      if (messages.includes('success')) {
        window.location.href = redirectUrl;
        return;
      }
      const newValidationMessages = [];
      messages.forEach((message) => {
        // eslint-disable-next-line default-case
        switch (message) {
          case 'password':
            newValidationMessages.push('INVALID_PASSWORD');
            break;
          case 'email':
            newValidationMessages.push('INVALID_EMAIL');
            break;
        }
      });

      setFocusInvalidElement(true);
      /** To prevent accidental spam we wait 600ms */
      setTimeout(() => {
        setErrorMessage(error);
        setValidationMessages(newValidationMessages);
        setIsLoading(false);
      }, 600);
    } catch (error) {
      setErrorMessage('error');
      setIsLoading(false);
      logger.error('Call to /api/login failed', { error, siteId });
    }
  };

  useEffect(() => {
    let messageToLog = {};
    if (!errorMessage) return;
    if (errorMessage === '401') {
      messageToLog = {
        event: 'login_failure',
        eventInfo: {
          error_message: 'invalid login details',
        },
      };
    }
    if (errorMessage === '429') {
      messageToLog = {
        event: 'login_failure',
        eventInfo: {
          error_message: 'too many attempts',
        },
      };
    }
    if (errorMessage === '500') {
      messageToLog = {
        event: 'login_failure',
        eventInfo: {
          error_message: 'unexpected error',
        },
      };
    }
    logToDataLayer(messageToLog);
  }, [errorMessage]);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <div role="alert">
        {errorMessage === '401' && (
          <Message
            data-cy="login-error-unauthorized"
            messageType="error"
            header={texts.validation.FAILED_LOGIN}
            initiallyExpanded
          >
            <Typography>{texts.validation.INVALID_CREDENTIALS}</Typography>
          </Message>
        )}
        {errorMessage === '429' && (
          <Message
            data-cy="login-error-too-many-attempts"
            messageType="error"
            header={texts.validation.FAILED_LOGIN}
            initiallyExpanded
          >
            <Typography>{texts.validation.TOO_MANY_ATTEMPTS}</Typography>
          </Message>
        )}
        {(errorMessage === '500' || errorMessage === '400') && (
          <Message
            data-cy="login-error"
            messageType="error"
            header={texts.validation.TECHNICAL_ERROR_LOGIN}
            initiallyExpanded
          >
            <div>{texts.validation.TECHNICAL_ERROR_LOGIN_INFO}</div>
            <div>
              <FormattedMessage
                text={texts.validation.CONTACT_SUPPORT}
                values={{
                  customerService: (
                    <Link
                      className={styles.formattedMessageLink}
                      href={texts.invalidNewAccountToken.resetPasswordLink.href.url}
                    >
                      {texts.invalidNewAccountToken.resetPasswordLink.title}
                    </Link>
                  ),
                }}
              />
            </div>
          </Message>
        )}
      </div>
      <form className={styles.loginForm} onSubmit={handleSubmit} noValidate>
        {heading}
        {isGlobetrotter && <hr className={styles.headerDivider} />}
        {siteIdSelector}
        <div className={styles.textInputSpace}>
          <Typography>
            <label
              className={classNames(styles.label, isGlobetrotter && styles.uppercase)}
              htmlFor="login-email-input"
            >
              {texts.email}
            </label>
          </Typography>
          <ValidatedTextInput
            id="login-email-input"
            type="email"
            texts={texts}
            value={email}
            focusInvalidElement={focusInvalidElement}
            handleInputChange={(e) => {
              handleEmailChange(e);
              setFocusInvalidElement(false);
            }}
            setValidationMessages={setValidationMessages}
            validationMessages={validationMessages}
            validationKey="INVALID_EMAIL"
            disabled={disabled}
          />
        </div>
        <div className={styles.passwordContainer}>
          <div className={styles.passwordInputContainer}>
            <Typography
              as="label"
              className={classNames(styles.label, isGlobetrotter && styles.uppercase)}
              htmlFor="password-input"
            >
              {texts.password}
            </Typography>
            <ValidatedTextInput
              id="password-input"
              type={showPassword ? 'text' : 'password'}
              texts={texts}
              value={password}
              focusInvalidElement={focusInvalidElement}
              handleInputChange={(e) => {
                handlePasswordChange(e);
                setFocusInvalidElement(false);
              }}
              setValidationMessages={setValidationMessages}
              validationMessages={validationMessages}
              validationKey="INVALID_PASSWORD"
              disabled={disabled}
            />
            <button
              type="button"
              onClick={toggleShowPassword}
              aria-labelledby="toggleShowPasswordText"
            >
              <span className={styles.hideVisibility} id="toggleShowPasswordText">
                {texts?.accessibility?.togglePasswordVisibility}
              </span>
              <Icon
                path={
                  showPassword ? USER_INTERFACE_PASSWORD_EYE_SHOW : USER_INTERFACE_PASSWORD_EYE_HIDE
                }
                size="s"
              />
            </button>
          </div>
          <div className={styles.rememberMeContainer}>
            <Checkbox
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
              checked={rememberMe}
              uniqueId="rememberMe"
              disabled={disabled}
            >
              {texts.rememberMe}
            </Checkbox>
            {showForgotPasswordLink && (
              <Link className={classNames(styles.passwordLink)} href={forgottenPasswordUrl}>
                {texts.forgottenPasswordLink}
              </Link>
            )}
          </div>
        </div>

        <Button
          variant="primary"
          className={styles.button}
          type="submit"
          onClick={() => {}}
          disabled={isLoading || disabled}
          loading={isLoading}
        >
          {texts.login}
        </Button>
      </form>
    </>
  );
};
