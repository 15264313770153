export enum MainSiteId {
  VINGSE = 1,
  VINGNO = 3,
  SPIES = 11,
  TJAREBORG = 15,
  GLOBETROTTER = 18,
}

export const MAIN_SITE_IDS = {
  VINGSE: 1,
  VINGNO: 3,
  SPIES: 11,
  TJAREBORG: 15,
  GLOBETROTTER: 18,
};

export const MAIN_BASE_URLS = {
  [MAIN_SITE_IDS.VINGSE]: 'https://www.ving.se',
  [MAIN_SITE_IDS.VINGNO]: 'https://www.ving.no',
  [MAIN_SITE_IDS.SPIES]: 'https://www.spies.dk',
  [MAIN_SITE_IDS.TJAREBORG]: 'https://www.tjareborg.fi',
  [MAIN_SITE_IDS.GLOBETROTTER]: 'https://www.globetrotter.se',
};

export const SITE_IDS = {
  ...MAIN_SITE_IDS,
  OBC_GRAN_CANARIA: 135,
};
