import { tryCatch } from '../tryCatch';
import { ClientAdditionalArgs, ClientConfig, ClientLogger, LogLevel, TraceContext } from './types';

function serializeError(error: Error) {
  return {
    name: error.name,
    stackTrace: error.stack,
    message: error.message,
  };
}

function configureClientLogger({ baseUrl, environment }: ClientConfig) {
  function wrapLogMethod(level: LogLevel) {
    const logMethod = (traceContext: TraceContext, ...args: unknown[]) => {
      const [message, ...rest] = args;

      const payload: ClientAdditionalArgs = {
        traceContext,
        data: {
          message,
        },
      };

      for (const [index, data] of rest.entries()) {
        if (typeof data === 'object') {
          Object.assign(payload.data, data);
        } else {
          Object.assign(payload.data, { [String(index)]: data });
        }
      }

      if (environment === 'local') {
        const { message, ...rest } = payload.data;
        console.error('FrontEnd Logger', message, rest);
      }

      for (const [key, value] of Object.entries(payload.data)) {
        if (value instanceof Error) {
          payload.data[key] = serializeError(value);
        }
      }

      return tryCatch(() =>
        fetch(`${baseUrl}/log/${level}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(payload),
        })
      );
    };

    return logMethod;
  }

  function getLogger(): ClientLogger {
    let traceContext: TraceContext = {};

    const error = wrapLogMethod('error');
    const warn = wrapLogMethod('warn');
    const info = wrapLogMethod('info');
    const debug = wrapLogMethod('debug');

    return {
      warn: (...args: unknown[]) => warn(traceContext, ...args),
      info: (...args: unknown[]) => info(traceContext, ...args),
      error: (...args: unknown[]) => error(traceContext, ...args),
      debug: (...args: unknown[]) => debug(traceContext, ...args),
      setTrace(value: TraceContext) {
        traceContext = value;
      },
    };
  }

  return { getLogger };
}

export { configureClientLogger };
