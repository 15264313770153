/* eslint-disable react/function-component-definition */
import { useState, lazy, Suspense } from 'react';
import { Button } from '@bwoty-web/styleguide';

import { useSearchParams } from 'react-router-dom';
import { MAIN_BASE_URLS } from '@/utils/siteId';
import { LoginForm } from './Login';

import styles from './styles.module.css';
import { Typography } from '@bwoty-web/ui-kit';

const TravelAgencySelect = lazy(() =>
  import('../components/TravelAgencySelect/TravelAgencySelect')
);
export function getServerSideProps(props) {
  return {
    siteId: props.siteId,
    baseUrl: props.baseUrl,
    parentSiteId: props.parentSiteId,
  };
}

export default function LoginMyHotel(props) {
  const { baseUrl, parentSiteId, texts } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const [bookedWithSiteId, setBookedWithSiteId] = useState();
  const customRedirect = searchParams.get('redirect');
  const mainSiteUrl = MAIN_BASE_URLS[bookedWithSiteId];

  const handleSetBookedWithSiteId = (newSiteId) => {
    setBookedWithSiteId(newSiteId);
    if (bookedWithSiteId) {
      searchParams.set('mainSiteId', newSiteId);
      setSearchParams(searchParams);
    }
  };

  return (
    <div>
      <LoginForm
        disabled={bookedWithSiteId === undefined}
        siteId={bookedWithSiteId}
        texts={texts}
        baseUrl={baseUrl}
        mainSiteUrl={mainSiteUrl}
        myHotelsMainSiteId={parentSiteId}
        showForgotPasswordLink={bookedWithSiteId !== undefined}
        heading={
          <Typography as="h1" variant="headline-m">
            {texts.heading}
          </Typography>
        }
        customRedirect={customRedirect}
        siteIdSelector={
          <Suspense fallback="Loading">
            <TravelAgencySelect
              value={bookedWithSiteId}
              onValueChange={handleSetBookedWithSiteId}
            />
          </Suspense>
        }
      />
      <div className={styles.myHotelsLoginBottomContainer}>
        <Button onClick={() => window.history.back()}>Back</Button>
      </div>
    </div>
  );
}
