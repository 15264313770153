function logToDataLayer(data) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push(data);
}

export default logToDataLayer;
